
import Vue from 'vue'
import axios from 'axios'
import BoosterPicture from '../node_modules/nuxt-booster/runtime/components/BoosterPicture.vue'
import BoosterIframe from '../node_modules/nuxt-booster/runtime/components/BoosterIframe.vue'
import Company from '../components/blocks/Company.vue'
import Event from '../components/blocks/Event.vue'
import Opportunity from '../components/blocks/Opportunity.vue'
import News from '../components/blocks/News.vue'
import Banner from '../components/blocks/Banner.vue'
import LaunchBox from '../components/blocks/LaunchBox.vue'
import Social from '../components/blocks/Social.vue'
const en = require('@/assets/images/flags/en.svg')
const it = require('@/assets/images/flags/it.svg')
const pt = require('@/assets/images/flags/pt.svg')
const es = require('@/assets/images/flags/es.svg')
const de = require('@/assets/images/flags/de.svg')
const fr = require('@/assets/images/flags/fr.svg')
export default Vue.extend({
  name: 'Home',
  components: {
    Social,
    Company,
    Event,
    Opportunity,
    News,
    Banner,
    LaunchBox,
    BoosterIframe,
    BoosterPicture
  },
  layout: 'footer',
  data () {
    return {
      flags: { en, it, pt, es, fr, de },
      toggleLanguageOpen: false,
      activeIndex: null,
      countPublishedCampaigns: 0,
      sectionsVisibility: [],
      launchBoxes: [],
      banners: [],
      opportunities: [],
      companies: [],
      events: [],
      news: [],
      settingsBanner: [
        {
          maxWidth: 9999,
          slidesPerPage: 1,
          peekRight: '0px',
          gutter: 6
        }
      ],
      settings: [
        {
          maxWidth: 9999,
          slidesPerPage: 5,
          peekRight: '0px',
          gutter: 0
        },
        {
          maxWidth: 1440,
          slidesPerPage: 4,
          peekRight: '0px',
          gutter: 0
        },
        {
          maxWidth: 1280,
          slidesPerPage: 3,
          peekRight: '0px',
          gutter: 0
        },
        {
          maxWidth: 991,
          slidesPerPage: 2,
          peekRight: '60px',
          peekLeft: '0px',
          gutter: 0
        },
        {
          maxWidth: 768,
          slidesPerPage: 2,
          peekRight: '36px',
          gutter: 0
        },
        {
          maxWidth: 576,
          slidesPerPage: 1,
          peekRight: '36px',
          gutter: 0
        }
      ],
      search: {
        text: '',
        city: ''
      },
      $sanitizeTitle: null,
      posts: []
    }
  },
  async fetch() {
    const url = 'https://www.juicer.io/api/feeds/cving'

    try {
      const response = await axios.get(url, {
        params: {
          per: 10,
          page: 1
        }
      })
      if (response.data.posts && response.data.posts.items) {
        for (const post of response.data.posts.items) {
          this.posts.push({
            id: post.id,
            link: post.full_url,
            message: post.message, // Messaggio del post
            created_at: post.formatted_external_created_at, // Data di creazione
            author: post.poster_name, // Nome dell'autore del post
            author_image: post.poster_image, // Immagine dell'autore
            image: post.media.length > 0 ? post.media[0].image : null, // Immagine pubblicata (se presente)
            source: post.source.source, // Tipo di social media (es: Facebook, Instagram, ecc.)
            likes: post.likes,
            comments: post.comments
          })
        }
      } else {
        console.error('Nessun post trovato.')
      }
    } catch (error) {
      console.error('Errore durante il recupero dei post:', error)
    }

    try {
      const sectionsVisibilityRes = await this.$axios.get('/proxysbapi/home/sections-visibility', {
        timeout: 3000
      })
      this.sectionsVisibility = sectionsVisibilityRes?.data || []
    } catch (err) {
      if (this.$auth.loggedIn) {
        this.$sentry.setTag('email', this.$auth.user.email)
      }
      this.$sentry.captureException(err)
      console.log('Errore nella richiesta sections-visibility: ', err)
      this.sectionsVisibility = []
    }

    try {
      const countPublishedCampaignsRes = await this.$axios.get('/proxysbapi/home/count-published-campaigns', {
        timeout: 3000
      })
      const count = Number(countPublishedCampaignsRes?.data?.count)
      this.countPublishedCampaigns = isNaN(count) ? null : count
    } catch (err) {
      if (this.$auth.loggedIn) {
        this.$sentry.setTag('email', this.$auth.user.email)
      }
      this.$sentry.captureException(err)
      console.log('Errore nella richiesta count-published-campaigns: ', err)
      this.countPublishedCampaigns = null
    }

    try {
      const launchBoxesRes = await this.$axios.get('/proxysbapi/home/launch-box', {
        timeout: 3000
      })
      this.launchBoxes = launchBoxesRes?.data || []
    } catch (err) {
      if (this.$auth.loggedIn) {
        this.$sentry.setTag('email', this.$auth.user.email)
      }
      this.$sentry.captureException(err)
      console.log('Errore nella richiesta launch-box: ', err)
      this.launchBoxes = []
    }

    try {
      const bannersRes = await this.$axios.get('/proxysbapi/home/banner', {
        timeout: 3000
      })
      this.banners = bannersRes?.data || []
    } catch (err) {
      if (this.$auth.loggedIn) {
        this.$sentry.setTag('email', this.$auth.user.email)
      }
      this.$sentry.captureException(err)
      console.log('Errore nella richiesta banner: ', err)
      this.banners = []
    }

    try {
      const opportunitiesRes = await this.$axios.get('/proxysbapi/home/campaigns-opportunities', {
        params: { lang: this.$i18n.locale },
        timeout: 3000
      })
      this.opportunities = opportunitiesRes?.data || []
    } catch (err) {
      if (this.$auth.loggedIn) {
        this.$sentry.setTag('email', this.$auth.user.email)
      }
      this.$sentry.captureException(err)
      console.log('Errore nella richiesta campaigns-opportunities: ', err)
      this.opportunities = []
    }

    try {
      const companiesRes = await this.$axios.get('/proxysbapi/home/featured-companies', {
        params: { limit: 10 },
        timeout: 3000
      })
      this.companies = companiesRes?.data || []
    } catch (err) {
      if (this.$auth.loggedIn) {
        this.$sentry.setTag('email', this.$auth.user.email)
      }
      this.$sentry.captureException(err)
      console.log('Errore nella richiesta featured-companies: ', err)
      this.companies = []
    }

    try {
      const eventsRes = await this.$axios.get('/proxysbapi/home/events', {
        timeout: 3000
      })
      this.events = eventsRes?.data || []
    } catch (err) {
      if (this.$auth.loggedIn) {
        this.$sentry.setTag('email', this.$auth.user.email)
      }
      this.$sentry.captureException(err)
      console.log('Errore nella richiesta events: ', err)
      this.events = []
    }

    try {
      const newsRes = await this.$axios.get('/proxymedia/wp-json/wp/v2/posts', {
        params: { per_page: 10, categories_exclude: 70, _embed: true, _fields: 'title,date,link, yoast_head_json.description' },
        timeout: 3000
      })
      this.news = newsRes?.data || []
    } catch (err) {
      if (this.$auth.loggedIn) {
        this.$sentry.setTag('email', this.$auth.user.email)
      }
      this.$sentry.captureException(err)
      console.log('Errore nella richiesta news: ', err)
      this.news = []
    }
  },
  head (): any {
    const json = {
      '@context': 'http://schema.org',
      '@type': 'Corporation',
      '@id': 'https://id.cving.com',
      legalName: 'CVing S.r.l.',
      url: 'https://cving.com',
      address: {
        '@type': 'PostalAddress',
        addressLocality: 'Milano',
        addressCountry: 'Italia',
        postalCode: '20144',
        streetAddress: 'Via Tortona, 33'
      },
      logo: 'https://cving.com/logo-cving.png',
      sameAs: [
        'https://www.facebook.com/CVINGapp',
        'https://www.linkedin.com/company/cving/',
        'https://www.instagram.com/cving_app/',
        'https://www.crunchbase.com/organization/cving'
      ]
    }
    return {
      script: [{ type: 'application/ld+json', json }],
      bodyAttrs: {
        class: 'homepage'
      },
      title: 'CVing',
      meta: [
        { hid: 'robots', name: 'robots', content: 'noindex,nofollow' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1.0, user-scalable=yes, minimum-scale=1.0, maximum-scale=1.0' },
        { name: 'apple-mobile-web-app-capable', content: 'yes' },
        { name: 'apple-mobile-web-app-status-bar-style', content: 'black-translucent' }, // Nasconde la barra su iOS
        { name: 'mobile-web-app-capable', content: 'yes' },
        { name: 'theme-color', content: '#000000' },
        // { rel: 'manifest', href: '/manifest.json' },
        { hid: 'description', name: 'description', content: (this as any).$t('generic.metaDescription') },
        { hid: 'og:description', name: 'og:description', content: (this as any).$t('generic.metaDescription') }
      ]
    }
  },
  computed: {
    getLangs():{code:string}[] {
      const langs = JSON.parse(JSON.stringify(this.$i18n.locales))
      return langs.sort((l: { code: string }, m: { code: any }) => l.code.localeCompare(m.code))
    },
    link () {
      let link = ''
      if (this.search.city !== '') {
        if (this.search.text !== '') {
          link = this.localePath({ name: 'radar-city', params: { city: this.$sanitizeTitle(this.search.city) }, query: { search: this.$sanitizeTitle(this.search.text), radius: '500000', p: '1', ea: '1', verified: 'true', suggested: 'false', order: '0' } })
        } else {
          link = this.localePath({ name: 'radar-city', params: { city: this.$sanitizeTitle(this.search.city) }, query: { radius: '500000', p: '1', ea: '1', verified: 'true', suggested: 'false', order: '0' } })
        }
      } else if (this.search.text !== '') {
        link = this.localePath({ name: 'radar', query: { search: this.$sanitizeTitle(this.search.text), p: '1', ea: '1', verified: 'true', suggested: 'false', order: '0' } })
      }
      return link
    },
    items() {
      return [
        {
          class: 'pi pi-user',
          label: 'Candidati',
          items: [
            { label: 'CVing.com', url: 'https://cving.com/', class: 'cving-link' },
            {
              label: this.$t('footer.searchJob').toString(),
              url: 'https://cving.com/offerte-lavoro?p=1',
              class: 'search-job-link'
            },
            {
              label: this.$t('footer.suggestFriend').toString(),
              url: 'https://business.cving.com/openreferralprogram',
              class: 'suggest-friend-link'
            },
            {
              label: this.$t('footer.signUp').toString(),
              url: 'https://cving.com/signup',
              class: 'signup-link'
            },
            { label: 'News', url: 'https://media.cving.com/', class: 'news-link' },
            {
              label: this.$t('footer.personalityTest').toString(),
              url: 'https://cving.com/user/test',
              class: 'personality-test-link'
            },
            {
              label: 'Training',
              url: 'https://business.cving.com/training-school/',
              class: 'training-link'
            },
            {
              label: 'Customer support',
              url: 'https://support.cving.com/hc/it',
              class: 'customer-support-link'
            }
          ]
        },
        {
          class: 'pi pi-briefcase',
          label: 'Aziende',
          items: [
            {
              label: this.$t('footer.areYouACompany').toString(),
              url: 'https://business.cving.com/platform',
              class: 'company-platform-link'
            },
            {
              label: this.$t('footer.solution').toString(),
              url: 'https://business.cving.com/per-le-aziende#solutions',
              class: 'solutions-link'
            },
            {
              label: this.$t('footer.comunication').toString(),
              url: 'https://business.cving.com/per-le-aziende#communication',
              class: 'communication-link'
            },
            {
              label: this.$t('footer.events').toString(),
              url: 'https://business.cving.com/per-le-aziende#events',
              class: 'events-link'
            },
            {
              label: this.$t('footer.indeed').toString(),
              url: 'https://business.cving.com/per-le-aziende#indeed',
              class: 'indeed-link'
            },
            {
              label: this.$t('footer.publish').toString(),
              url: this.$i18n.locale === 'it'
                ? 'https://easypost.cving.com'
                : 'https://easypost.cving.com/?lang=' + this.$i18n.locale,
              class: 'publish-link'
            }
          ]
        },
        {
          class: 'pi pi-building',
          label: 'Corporate',
          items: [
            { label: 'Legal info', url: 'https://media.cving.com/legal-info/', class: 'legal-info-link' }
          ]
        }
      ]
    }
  },

  methods: {
    getIframeSrc() {
      return this.$i18n.locale === 'it'
        ? 'https://cloud.news.cving.com/cvingform_ita'
        : 'https://cloud.news.cving.com/cvingform_en'
    },
    toggleMenu(index) {
      this.activeIndex = this.activeIndex === index ? null : index
    },
    isSectionVisible(sectionName:string) {
      const section = this.sectionsVisibility.find((item: { sectionName: string }) => item.sectionName === sectionName)
      return section ? section.visible : true
    },
    changeLanguage(lang: string) {
      // Chiudi l'OverlayPanel programmaticamente
      const overlayPanel = this.$refs.op as Vue & { hide: () => void }
      if (overlayPanel && typeof overlayPanel.hide === 'function') {
        overlayPanel.hide()
        this.toggleLanguageOpen = false
      } else {
        console.log('hide non esiste su questo riferimento.')
      }
      const name = this.$route.name.replace(/___\w+$/, '') + '___' + lang
      const newRoute = this.$router.resolve({
        name,
        params: this.$route.params,
        query: this.$route.query,
        hash: this.$route.hash
      })
      setTimeout(() => {
        this.$router.push(newRoute.location)
        // this.setPreviousLanguage(this.$i18n.locale)
        this.$i18n.locale = lang
        const mainContainer = document.getElementsByClassName('main-container-scroll')[0]
        if (mainContainer) {
          mainContainer.scrollTo({
            top: 0,
            behavior: 'smooth'
          })
        }
      }, 250)
    },
    toggleLanguage(event) {
      const overlayPanel = this.$refs.op as any

      if (overlayPanel && typeof overlayPanel.toggle === 'function') {
        overlayPanel.toggle(event)
        this.toggleLanguageOpen = !this.toggleLanguageOpen
      } else {
        console.log('Il metodo toggle non esiste su questo riferimento.')
      }
    },
    openLink(link: string | URL) {
      // Apri il link in una nuova scheda del browser
      const newWindow = window.open(link, '_blank')
      if (newWindow) {
        newWindow.opener = null // Evita che la finestra aperta possa accedere all'opener
        newWindow.focus() // Porta la nuova scheda in primo piano
      } else {
        // Gestisci il blocco dei popup
        alert('I popup sono bloccati dal tuo browser. Abilita i popup per aprire il link.')
      }
    },
    searchCampaigns () {
      this.$router.push(this.link)
    }
  }
})
